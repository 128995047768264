.wa-wrapper {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.wa-wrapper .wa-topbar {
    display: flex;
    display: -webkit-flex;
    padding-bottom: 20px;
    padding-top: 20px;
}

.wa-wrapper .wa-topbar .content-left {
    display: flex;
    display: -webkit-flex;
    width: 50%;
    align-items: center;
    padding-left: 20px;
}

.wa-wrapper .wa-topbar .content-right {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    width: 50%;
    flex-direction: row-reverse;
    padding-right: 20px;
}

.wa-wrapper .wa-tabs {
    display: flex;
    display: -webkit-flex;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
}

.wa-wrapper .wa-tabs ul {
    display: flex;
    display: -webkit-flex;
    margin: 0;
    padding: 0;
}

.wa-wrapper .wa-tabs ul .wa-tab-item {
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    font-size: 14px;
    border-bottom: 3px solid transparent;
    height: 100%;
}

.wa-wrapper .wa-tabs ul .wa-tab-item.active {
    border-bottom: 3px solid rgba(0, 0, 0, .7);
}

.wa-title {
    font-size: 22px;
    font-weight: bold !important;
    color: rgba(0, 0, 0, .7);
}

.wa-back-btn {
    transition: ease .3s;
    display: flex;
    display: -webkit-flex;
    outline: none !important;
    border: none !important;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 20px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.wa-back-btn:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
}

.wa-back-btn i {
    width: 18px;
    height: 18px;
}

.wa-back-btn i svg {
    width: 100%;
    height: 100%;
}

.wa-btn {
    transition: ease .3s;
    display: flex;
    display: -webkit-flex;
    outline: none !important;
    border: none !important;
    background-color: rgb(50, 101, 255);
    color: rgba(255, 255, 255, .9);
    padding: 10px 15px;
    border-radius: 5px;
    font-style: 14px;
    align-items: center;
}

.wa-btn.flat {
    transition: ease .3s;
    display: flex;
    display: -webkit-flex;
    outline: none !important;
    border: 2px solid rgba(0, 0, 0, .1) !important;
    background-color: transparent;
    color: rgba(0, 0, 0, .7);
    padding: 10px 15px;
    border-radius: 5px;
    font-style: 14px;
    align-items: center;
}

.wa-btn.flat:hover {
    background-color: rgba(0, 0, 0, .02);
}

.wa-btn:hover {
    background-color: rgb(48, 94, 233);
}

.wa-btn i {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.wa-btn i svg {
    width: 100%;
    height: 100%;
}

.wa-tab-content {
    display: flex;
    display: -webkit-flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.wa-tab-content .content-left {
    display: flex;
    display: -webkit-flex;
    padding-left: 20px;
    padding-right: 10px;
    width: 50%;
}

.wa-tab-content .content-right {
    display: flex;
    display: -webkit-flex;
    padding-right: 20px;
    padding-left: 10px;
    width: 50%;
}

.wa-card {
    display: flex;
    display: -webkit-flex;
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.wa-card .wa-card-content {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
}

.wa-card .wa-card-header {
    display: flex;
    display: -webkit-flex;
}

.wa-card .wa-card-header i {
    width: 30px;
    height: 30px;
    color: limegreen;
    margin-right: 10px;
}

.wa-card .wa-card-header i svg {
    width: 100%;
    height: 100%;
}

.wa-card .wa-card-title {
    font-size: 20px;
    line-height: initial;
    font-weight: bold !important;
}

.wa-card .wa-card-subtitle {
    font-size: 16px;
    color: rgb(0, 210, 218);
}

.wa-card .wa-card-text {
    font-size: 14px;
    color: 14px;
}

.wa-card .content-left {
    display: flex;
    display: -webkit-flex;
    padding-left: 20px;
    padding-right: 10px;
    width: 50%;
    border-right: 2px solid rgba(0, 0, 0, .1);
}

.wa-card .content-right {
    display: flex;
    display: -webkit-flex;
    padding-right: 20px;
    padding-left: 20px;
    width: 50%;
}
