.row-flex {
   display: flex;
   display: -webkit-flex;
   width: 100%;
}

.card-progress-leads-total {
   display: flex;
   display: -webkit-flex;
   border-radius: 10px;
   padding: 20px;
   width: 100%;
   margin-top: 20px;
   margin-bottom: 20px;
   align-items: center;
   margin-left: 5px;
   margin-right: 5px;
   box-shadow: rgb(0 0 0 / 16%) 1px 1px 4px;
}

.card-progress-leads-total img {
   width: 50px;
   height: 50px;
   margin-right: 10px;
}

.card-progress-leads-total .big-number {
   font-size: 30px;
   font-weight: bold;
}

.card-progress-leads-total .wrapper-content {
   display: flex;
   display: -webkit-flex;
   width: 100%;
   justify-content: center;
}

.details-btn {
   outline: none;
   border: 1px solid rgba(0, 0, 0, .2);
   background-color: transparent;
   border-radius: 5px;
}

.oq-progress-modal {
   background-color: #fff;
   border-radius: 10px;
   box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
   min-width: 300px;
   max-width: 450px;
   overflow: hidden;
   padding: 0;
   margin: 20px;
}

.graph-content {
   display: flex;
   display: -webkit-flex;
   margin-top: 20px;
   min-height: 300px;
   justify-content: space-around;
   border-radius: 10px;
   border: 1px solid rgba(0, 0, 0, .2);
   padding: 20px;
}

.graph-content .wrapper-bar {
   display: flex;
   display: -webkit-flex;
   justify-content: flex-end;
   flex-direction: column;
   margin-left: 10px;
   margin-right: 10px;
}

.graph-content .wrapper-bar .bar {
   display: flex;
   display: -webkit-flex;
   align-items: center;
   justify-content: center;
   min-height: 30px;
   background-color: var(--primary-color);
   color: var(--secondary-color);
   font-size: 12px;
   border-radius: 5px;
}

.graph-content .wrapper-bar .icon-bar {
   margin-top: 10px;
}

.graph-content .wrapper-bar .icon-bar img {
   width: 50px;
   height: 50px;
}